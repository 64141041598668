<template>
  <div>
    <div v-if="loading"><Loader /></div>

    <div v-if="!loading">
      <vue-page-transition name="fade-in-up ">
        <div class="LayoutDefault">
          <Navigation />
          <main class="LayoutDefault__main">
            <router-view />
          </main>
          <footer>
            <Footer />
          </footer>
        </div>
      </vue-page-transition>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Loader,
    Navigation,
    Footer,
  },
  name: `App`,
  data() {
    return {
      layout: `div`,
      loading: true,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    const self = this;

    let i = setTimeout(() => {
      self.loading = false;
    }, 3000);
    // clearTimeout(i);
  },

  methods: {
    handleScroll() {
      var h = document.documentElement,
        b = document.body,
        st = "scrollTop",
        sh = "scrollHeight";
      const percent =
        ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100 + 1;
      let body = document.getElementsByTagName("body")[0];
      body.style.setProperty("--scroll", percent + "%");
    },
  },
};
</script>

<style lang="scss">
body::-webkit-scrollbar-thumb {
  background-color: #f2f2f0;
  position: fixed;
  top: 999px;
  height: var(--scroll);
  display: none;
}

body::-webkit-scrollbar {
  width: 0.4em;
  background-color: #202056;
  position: relative;
}
body::-webkit-scrollbar:hover {
  width: 0.8em;
}
body::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px #202056;
  background: linear-gradient(180deg, #f2f2f0 var(--scroll), #202056 10%);
}

.LayoutDefault {
  max-width: 1263.33px;
  margin: auto;
  @include respond(big-desktop) {
    max-width: 1263.33px;
  }
  @include respond(tab-land) {
    max-width: 98rem;
  }
  @include respond(tab-port) {
    max-width: 700px;
    //  display: none;
  }
  @include respond(phone) {
    min-width: calc(100% - 10px);
    margin: auto;
  }
}
</style>
