<template>
  <div>
    <div id="myNav" class="overlay">
      <span class="closebtn" @click="closeNav"
        ><img src="@/assets/svg/close-icon.svg" class="closeNav"
      /></span>
      <div class="overlay-content">
        <a href="javascript:void(0)" @click="linkClicked('/')">Home</a>
        <a href="javascript:void(0)" @click="linkClicked('/about')">About us</a>
        <a href="javascript:void(0)" @click="linkClicked('/work')">Our work</a>
        <a href="javascript:void(0)" @click="linkClicked('/service')"
          >Services</a
        >
        <a href="javascript:void(0)" @click="linkClicked('/contact')"
          >Contact us</a
        >
      </div>
    </div>

    <div class="navigation">
      <div class="navigation__brand">
        <router-link to="/">
          <img src="@/assets/logo.png" />
        </router-link>
      </div>
      <div class="navigation__toggler">
        <span @click="openNav"
          ><img src="@/assets/svg/menu-icon.svg" class="openNav"
        /></span>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  methods: {
    openNav: () => {
      document.getElementById("myNav").style.height = "100%";
      document.body.style.overflow = "hidden";
    },
    closeNav: () => {
      document.getElementById("myNav").style.height = "0%";
      document.body.style.overflow = "auto";
    },
    linkClicked: (goto) => {
      document.getElementById("myNav").style.height = "0%";
      document.body.style.overflow = "auto";
      router.push(goto);
    },
  },
};
</script>
<style lang="scss">
.overflow-hidden {
  overflow: hidden;
}
.overlay {
  width: 100%;
  height: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: $color-white;
  overflow-x: hidden;
  transition: 0.5s;
  overflow: hidden;

  &-content {
    position: relative;
    top: 20%;
    width: 30%;
    margin: 30px auto;
    @include respond(tab-port) {
      width: 40%;
    }
    @include respond(phone) {
      top: 10%;
      width: 78%;
    }
  }

  & a {
    padding: 0.8rem;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    transition: 0.3s;
    display: block;
    font-size: 3.5rem;
    font-weight: 900;
    padding: 1rem 2rem;
    color: $color-white;
    letter-spacing: 0.5rem;
    transition: 0.2s;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 0.12rem;
    -webkit-text-stroke-color: #3d3d80;
    // background: linear-gradient(to right, #3d3d80 50%, white 50%);
    background: linear-gradient(to top, #3d3d80 50%, #fff 50%);
    background-size: 100% 200%;
    background-position: top left;
    transition: all 1s ease-out;
    background-clip: text;
    -webkit-background-clip: text;
    &:hover {
      // background-position: left bottom;
      background-position: bottom left;
    }
  }

  & .closebtn {
    position: absolute;
    top: 2rem;
    cursor: pointer;
    right: 4.5rem;
    font-size: 6rem;
    @include respond(phone) {
      font-size: 4rem;
      top: 1.5rem;
      right: 3.5rem;
    }
  }
}
.navigation {
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  &__brand {
    height: 5.5rem;
    width: 5.5rem;
    // background: white;
    // border-radius: 1.45rem;
    img {
      max-width: 100%;
      min-width: 100%;
      max-height: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
  &__toggler {
    font-size: 3rem;
    color: $color-white;
    cursor: pointer;
  }
}
.openNav {
  width: 60%;
  float: right;
  @include clearfix;
}
.closeNav {
  width: 60%;
}
</style>