<template>
  <div class="loader">
    <div class="line" id="line" :style="{ width: `${counter}%` }"></div>
    <div class="centered">
      <div class="box">
        <p class="number" id="counter">{{ counter }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `Loader`,
  data() {
    return {
      counter: 0,
    };
  },
  mounted() {
    const self = this;
    var i = setInterval(function () {
      self.counter += 1;
      if (self.counter == 100) {
        clearInterval(i);
      }
    }, 30);
  },
};
</script>

<style lang="scss" scoped>
.loader {
  height: 90vh;
  position: relative;
}
.centered {
  @include absCenter;
}
.line {
  height: 0.3rem;
  background-color: #ffffff;
  z-index: -1;
  position: absolute;
  top: 50%;
}
.box {
  background-color: #3d3d80;
  margin: auto;
  border: 2px solid #ffffff;
  min-width: 20rem;
  min-height: 20rem;
  max-width: 20rem;
  max-height: 20rem;
  line-height: 12rem;
  text-align: center;
  padding: 2rem;
  -webkit-box-shadow: 0px 0px 24px -5px rgba(0, 0, 0, 0.66);
  box-shadow: 0px 0px 24px -5px rgba(0, 0, 0, 0.66);
  position: relative;
  ::after {
    content: "";
    position: absolute;
    border: 1px solid blue;
    left: -3rem;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 2rem;
    background-color: #3d3d80;
    border: 2px solid #ffffff;
  }
}
.number {
  font-size: 100px;
  font-weight: bold;
  color: #6868ab;
}
</style>
