<template>
  <div class="Home">
    <Slider />
    <div class="cursor_div"></div>
    <div class="whatwedo">
      <h1 class="whatwedo__title">What We do</h1>
      <div
        class="todo_container"
        :class="whatwedo.id != 1 ? 'margintop' : ''"
        v-for="(whatwedo, index) in whatwedos.slice(0, 4)"
        :key="index"
      >
        <h1 class="number">
          {{ minimum >= index ? `0${index + 1}` : index }}
        </h1>
        <div class="todo_container__text">
          <h3>{{ whatwedo.title }}</h3>
          <p>{{ whatwedo.descriptions }}</p>
          <router-link
            :to="{
              name: 'Detail',
              params: {
                descriptions: whatwedo.descriptions,
                title: whatwedo.title,
                image: whatwedo.image,
              },
            }"
            class="todo_link1"
            >Read More</router-link
          >
        </div>
        <div class="todo_container__image">
          <div
            class="colored__div"
            :style="{ backgroundColor: whatwedo.color }"
          ></div>
          <img :src="require('@/assets/home/works/' + whatwedo.image)" />
        </div>
      </div>
    </div>

    <div class="ourwork">
      <h1 class="ourwork__title">Our Work</h1>

      <div class="bg-container" id="bg-container">
        <div class="background-second"></div>
      </div>

      <div class="row row-flexed">
        <div
          class="col-1-of-3"
          v-for="(work, index) in works.slice(3, 7)"
          :key="index"
        >
          <div class="card">
            <div class="card__image">
              <img :src="require(`@/assets/home/works/${work.image}`)" />
            </div>
            <div class="card__body">
              <h3>{{ work.title }}</h3>
              <p>{{ work.description }}</p>
            </div>
            <div class="card__footer">
              <a :href="work.link" target="_blank">INITIATE PROJECT</a>
            </div>
          </div>
        </div>

        <div class="col-2-of-3">
          <div
            class="see-more"
            :style="
              numberworks % 3 != 0
                ? { 'margin-top': '18.3rem' }
                : { 'margin-top': '0rem' }
            "
          >
            <h1>See More Projects</h1>
            <router-link to="/work">View More</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="other-companies">
      <div>
        <img src="@/assets/home/Marriott.png" />
        <img src="@/assets/home/giz.png" />
      </div>
      <div>
        <img
          src="@/assets/home/Screen_Shot_2021-04-10_at_07.16.32-removebg-preview@3x.png"
        />
        <img src="@/assets/home/portfolio-clien.png" />
      </div>
    </div>
  </div>
</template>

<script>
// import LayoutDefault from "../layouts/LayoutDefault.vue";
import Slider from "../components/Slider.vue";
import WhatWeDo from "@/database/whatwedo.json";
import Work from "@/database/work.json";
export default {
  name: `Home`,
  components: {
    Slider,
  },
  data() {
    return {
      minimum: 9,
      whatwedos: WhatWeDo,
      works: Work,
      numberworks: 0,
    };
  },
  created() {
    // this.$emit(`update:layout`, LayoutDefault);
  },
  mounted() {
    // this.numberworks = this.works.length; when all works is displayed in home.
    this.numberworks = 4;
    document
      .getElementById("bg-container")
      .style.setProperty("--number", Math.ceil(this.numberworks / 3));
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss">
.whatwedo {
  margin-bottom: 20rem;
  &__title {
    font-size: 4.5rem;
    font-family: "Manrope", sans-serif;
    font-weight: 800;
    color: #f5f5f5;
    text-transform: uppercase;
    margin-bottom: 5rem;
    margin-top: 10rem;
    @include respond(phone) {
      text-align: center;
    }
  }
  & .todo_container {
    background: #202056;
    padding: 5rem;
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: row;
    // align-items: center;
    position: relative;
    z-index: 1;
    @include respond(tab-land) {
      height: 40vh;
    }
    @include respond(tab-port) {
      height: 50vh;
    }
    @include respond(phone) {
      flex-direction: column-reverse;
      margin: auto;
      margin-top: 5rem;
      height: 85vh;
      padding: 3rem 0;
      width: 99%;
      //   display: none;
    }
    & .number {
      position: absolute;
      font-size: 20rem;
      color: #d4d4d4;
      right: 0;
      top: -14rem;
      z-index: 9999999999;
      font-family: "Manrope", sans-serif;
      @include respond(phone) {
        font-size: 10rem;
        top: -7rem;
      }
    }
    &__text {
      width: 35%;
      font-family: "Raleway", sans-serif;
      align-self: center;
      z-index: 999;
      @include respond(phone) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 50%;
        padding-top: 4rem;
      }
      h3 {
        font-size: 2rem;
        margin-bottom: 3rem;
        color: #eeeeee;
        text-transform: uppercase;
      }
      p {
        font-size: 1.3rem;
        color: #bbbbbb;
      }
      a {
        text-decoration: none;
        color: #bbbbbb;
        font-size: 1.2rem;
        border: 1px solid #bbbbbb;
        padding: 1.4rem 3rem;
        display: inline-block;
        margin-top: 5rem;
        text-align: center;
        &:hover {
          transition: 0.5s;
          background: #fff;
          color: #000;
        }
        @include respond(phone) {
          display: block;
        }
      }
      h3,
      p,
      a {
        width: 80%;
        @include respond(phone) {
          width: 70%;
        }
      }
    }

    &__image {
      position: relative;
      flex: 1;
      margin-left: 10rem;
      & .colored__div {
        background: #fff727;
        height: 45rem;
        width: 50%;
        position: absolute;
        z-index: -1;
        bottom: -10rem;
        @include respond(tab-land) {
          bottom: 2rem;
        }
        @include respond(tab-port) {
          bottom: 3rem;
          height: 80%;
          left: -8rem;
        }
        @include respond(phone) {
          bottom: 0;
          top: 1rem;
          height: 80%;
          left: -8rem;
        }
      }
      & img {
        max-width: 80%;
        min-width: 80%;
        object-fit: cover;
        max-height: 56rem;
        min-height: 56rem;
        width: auto;
        height: auto;
        margin-left: 10rem;
        box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
        -webkit-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
        -moz-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
        @include respond(phone) {
          position: absolute;
          max-width: 100%;
          min-width: 100%;
          max-height: 100%;
          min-height: 100%;
          margin-left: 0;
          left: -3.5rem;
          top: -1.5rem;
        }
      }
    }
  }
}

.margintop {
  &:not(:first-child) {
    margin-top: 30rem;
    @include respond(phone) {
      margin-top: 5rem;
    }
  }
}

.ourwork {
  margin-bottom: 4rem;

  &__title {
    font-size: 4.5rem;
    font-family: "Manrope", sans-serif;
    font-weight: 800;
    color: #f5f5f5;
    text-transform: uppercase;
    margin-bottom: 5rem;
    @include respond(phone) {
      text-align: center;
    }
  }
  & .card {
    background: $color-white;
    padding: 1.5rem;
    position: relative;
    min-height: 38.5rem;
    max-height: 38.5rem;
    box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
    -webkit-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
    -moz-box-shadow: 0px 20px 20px -7px rgba(0, 0, 0, 0.22);
    margin: 3rem 0;
    @include respond(tab-land) {
      min-height: 75rem;
      max-height: 75rem;
    }
    @include respond(phone) {
      min-height: 38.5rem;
      max-height: 38.5rem;
    }
    .row {
      margin: 0 !important;
      padding: 0 !important;
    }
    &__image {
      text-align: center;
      img {
        width: 100%;
        max-height: 21rem;
        object-fit: cover;
        @include respond(tab-land) {
          max-height: 50rem;
          min-height: 50rem;
        }
        @include respond(tab-port) {
          max-height: 50rem;
          min-height: 50rem;
        }
        @include respond(phone) {
          max-height: 21rem;
          min-height: 21rem;
        }
      }
    }
    &__body {
      h3 {
        color: #606060;
        font-size: 1.2rem;
        margin: 1rem 0;
        @include respond(tab-land) {
          font-size: 2rem;
        }
        @include respond(phone) {
          font-size: 1rem;
        }
      }
      p {
        font-size: 1rem;
        color: #606060;
        @include respond(tab-land) {
          font-size: 1.5rem;
        }
        @include respond(phone) {
          font-size: 1rem;
        }
      }
    }
    &__footer {
      position: absolute;
      bottom: 2rem;
      a {
        color: #7a7a7ab7;
        font-weight: bold;
        text-decoration: none;
        @include respond(phone) {
          font-size: inherit;
        }

        &:hover {
          color: #000;
        }
      }
    }
  }
  & .see-more {
    h1 {
      text-transform: uppercase;
      color: #202056;
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 0.12rem;
      -webkit-text-stroke-color: #fff;
      // background: linear-gradient(to right, #3d3d80 50%, white 50%);
      background: linear-gradient(to top, #fff 50%, transparent 50%);
      background-size: 100% 200%;
      background-position: top left;
      transition: all 1s ease-out;
      background-clip: text;
      cursor: pointer;
      -webkit-background-clip: text;
      &:hover {
        // background-position: left bottom;
        background-position: bottom left;
      }
      font-size: 6.4rem;
      @include respond(phone) {
        font-size: 2.8rem;
        // display: none;
      }
    }
    a {
      color: $color-white;
      border: 1px solid $color-white;
      padding: 3.5rem 15rem;
      display: inline-block;
      font-size: 2rem;
      text-decoration: none;
      cursor: pointer;
      margin-top: 6.2rem;
      @include respond(phone) {
        // padding: 2.5rem 10rem;
        font-size: 1.5rem;
      }
      &:hover,
      &:focus {
        background-color: #fff;
        color: #000;
        transition: 0.3s;
      }
    }
  }
}
.bg-container {
  position: relative;

  .background-second {
    height: calc(730px + var(--number) * 38.5rem);
    position: absolute;
    top: 20rem;
    right: -5rem;
    background: #202056;
    width: 90%;
    z-index: -1;
    @include respond(tab-land) {
      height: 490rem;
      right: -7.2rem;
    }
    @include respond(tab-port) {
      display: none;
    }
    @include respond(phone) {
      display: none;
    }
  }
}

.other-companies {
  background: $color-white;
  margin-top: 10rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @include respond(phone) {
    flex-direction: column;
  }
  div {
    display: flex;
    justify-content: space-around;
  }
  img {
    width: 30%;
    filter: grayscale(100%);
    @include respond(phone) {
      width: 50%;
    }
    &:hover {
      filter: grayscale(0%);
      filter: saturate(4);
    }
  }
}
.cursor_div {
  height: 100%;
  width: 100%;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    @include absCenter;
    display: block;
    width: 0.2rem;
    height: 2rem;
    margin: 0;
    top: -3rem;
    background: #fff;
    -webkit-animation-name: moveDown;
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 2s;
  }
  @-webkit-keyframes moveDown {
    0% {
      top: -4rem;
    }
    100% {
      top: -2rem;
    }
  }
}
.row {
  padding: 0;
}
.row-flexed {
  max-width: 100%;
  @include respond(phone) {
    max-width: 50rem;
  }
}
</style>
