<template>
  <div class="slider" style="margin-bottom: 0">
    <vueper-slides
      class="no-shadow"
      bullets-outside
      transition-speed="250"
      autoplay
      fade
    >
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :image="slide.image"
        :title="slide.title"
        :content="slide.content"
      />
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import Slide1 from "../assets/home/slider1.jpeg";
import Slide2 from "../assets/home/slider2.jpeg";
import Slide3 from "../assets/home/slider3.jpeg";
export default {
  name: "Slider",
  components: {
    VueperSlides,
    VueperSlide,
  },
  data: () => ({
    slides: [
      {
        title: "We do digital",
        content: "Marketing",
        image: Slide1,
      },
      {
        title: "We develop",
        content: "Softwares",
        image: Slide2,
      },
      {
        title: "We do responsive",
        content: "design & Branding",
        image: Slide3,
      },
    ],
  }),
};
</script>


<style lang="scss">
.slider {
  margin-top: 2rem;
}
.ex--center-mode {
  width: 60rem;
  max-width: 100%;
  margin: auto;
}
.vueperslides__arrow {
  display: none;
}
.vueperslides__bullets {
  justify-content: flex-end;
}
.vueperslides__bullet .default {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 1.6rem;
  height: 1.6rem;
}
.vueperslides__bullet--active .default {
  background-color: $color-white;
  height: 2rem;
  width: 2rem;
}
.vueperslides__parallax-wrapper {
  padding-bottom: 40% !important;
  @include respond(phone) {
    padding-bottom: 100% !important;
  }
}
.vueperslide__content-wrapper {
  position: absolute;
  left: 0;
  bottom: -13rem;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 3.4rem;
  font-weight: bolder;
  text-transform: uppercase;
  align-items: flex-start !important;
  margin-left: 3.5rem !important;
}
.vueperslide__title {
  margin-bottom: 1.5rem;
}
</style>