import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueGeolocation from 'vue-browser-geolocation';
Vue.use(VueGeolocation);
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.config.productionTip = false
import VuePageTransition from 'vue-page-transition'

Vue.use(VuePageTransition)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCnI4O3l-4MKxNBcS7UrFLLvMx8z2nTCzk'
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
