import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/work",
    name: "Work",
    component: () => import('../views/Works.vue')
  },
  // {
  //   path: '/l',
  //   name: 'Loader',
  //   component: () => import('../components/Loader.vue')
  // },
  {
    path: "/service",
    name: "Service",
    component: () => import('../views/Services.vue')
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import('../views/Contacts.vue')
  },
  {
    path: "/detail",
    name: "Detail",
    component: () => import('../views/WorkDetail.vue'),
    props: true
  },
  {
    path: "/**",
    name: "Notfound",
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
