var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Home"},[_c('Slider'),_c('div',{staticClass:"cursor_div"}),_c('div',{staticClass:"whatwedo"},[_c('h1',{staticClass:"whatwedo__title"},[_vm._v("What We do")]),_vm._l((_vm.whatwedos.slice(0, 4)),function(whatwedo,index){return _c('div',{key:index,staticClass:"todo_container",class:whatwedo.id != 1 ? 'margintop' : ''},[_c('h1',{staticClass:"number"},[_vm._v(" "+_vm._s(_vm.minimum >= index ? ("0" + (index + 1)) : index)+" ")]),_c('div',{staticClass:"todo_container__text"},[_c('h3',[_vm._v(_vm._s(whatwedo.title))]),_c('p',[_vm._v(_vm._s(whatwedo.descriptions))]),_c('router-link',{staticClass:"todo_link1",attrs:{"to":{
            name: 'Detail',
            params: {
              descriptions: whatwedo.descriptions,
              title: whatwedo.title,
              image: whatwedo.image,
            },
          }}},[_vm._v("Read More")])],1),_c('div',{staticClass:"todo_container__image"},[_c('div',{staticClass:"colored__div",style:({ backgroundColor: whatwedo.color })}),_c('img',{attrs:{"src":require('@/assets/home/works/' + whatwedo.image)}})])])})],2),_c('div',{staticClass:"ourwork"},[_c('h1',{staticClass:"ourwork__title"},[_vm._v("Our Work")]),_vm._m(0),_c('div',{staticClass:"row row-flexed"},[_vm._l((_vm.works.slice(3, 7)),function(work,index){return _c('div',{key:index,staticClass:"col-1-of-3"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card__image"},[_c('img',{attrs:{"src":require(("@/assets/home/works/" + (work.image)))}})]),_c('div',{staticClass:"card__body"},[_c('h3',[_vm._v(_vm._s(work.title))]),_c('p',[_vm._v(_vm._s(work.description))])]),_c('div',{staticClass:"card__footer"},[_c('a',{attrs:{"href":work.link,"target":"_blank"}},[_vm._v("INITIATE PROJECT")])])])])}),_c('div',{staticClass:"col-2-of-3"},[_c('div',{staticClass:"see-more",style:(_vm.numberworks % 3 != 0
              ? { 'margin-top': '18.3rem' }
              : { 'margin-top': '0rem' })},[_c('h1',[_vm._v("See More Projects")]),_c('router-link',{attrs:{"to":"/work"}},[_vm._v("View More")])],1)])],2)]),_vm._m(1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-container",attrs:{"id":"bg-container"}},[_c('div',{staticClass:"background-second"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"other-companies"},[_c('div',[_c('img',{attrs:{"src":require("@/assets/home/Marriott.png")}}),_c('img',{attrs:{"src":require("@/assets/home/giz.png")}})]),_c('div',[_c('img',{attrs:{"src":require("@/assets/home/Screen_Shot_2021-04-10_at_07.16.32-removebg-preview@3x.png")}}),_c('img',{attrs:{"src":require("@/assets/home/portfolio-clien.png")}})])])}]

export { render, staticRenderFns }